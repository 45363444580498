@use '../../theme/variables.module.scss' as *;

.avatar-container {
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  &-icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: $gray-85;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .avatar-icon {
      fill: $gray-100;
      color: transparent;
      font-size: 5rem;
    }
    .avatar-image {
      object-fit: cover;
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }
  }
  &-item {
    text-align: center;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
}
.avatar-image-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    color: $gray-100;
  }
}

.avatar-link {
  position: absolute;
  right: -50px;
  top: -10px;
  margin: 0;
  font-weight: 400;
  ion-icon {
    color: $on-primary-light;
    font-size: 0.95rem;
  }
}

.avatar-edit-photo-alert {
  .alert-wrapper {
    border-radius: $border-radius-external;
    background-color: $general;
  }
  .alert-head.sc-ion-alert-ios {
    text-align: left;
    .alert-title.sc-ion-alert-ios,
    .alert-sub-title.sc-ion-alert-ios {
      color: $on-general;
      font-size: 0.75rem;
      font-weight: 300;
      &:last-of-type {
        margin-top: 0;
      }
    }
  }
  .alert-message.sc-ion-alert-ios {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    img {
      width: 40px;
      margin-top: 1.25rem;
      margin-bottom: 0.75rem;
    }
    p {
      color: $on-general;
      font-weight: 500;
      font-size: 0.875rem;
      align-self: flex-start;
    }
  }
  .alert-button-group.sc-ion-alert-ios {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 1rem;
    gap: 1rem;
    .avatar-edit-photo-alert-action {
      width: 100%;
      background-color: $accent;
      border-radius: $border-radius-element;
      color: $on-accent;
      font-weight: 600;
      font-size: 0.75rem;
      height: 2.1875rem;
    }
    .avatar-edit-photo-alert-cancel {
      color: $on-general;
      font-size: 0.75rem;
      text-decoration: underline;
      font-weight: 400;
      text-underline-offset: 1px;
      border: unset;
      height: 2.1875rem;
    }
  }
}
