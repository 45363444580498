@use '../../theme/variables.module.scss' as *;

.install-alert-close {
  position: absolute;
  top: 0;
  right: 0.31rem;
  font-size: 1.125rem;
  cursor: pointer;
}

.install-alert {
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  opacity: 0;
  display: none;
  z-index: 1;
  &.top {
    top: 1.25rem;
    right: 0.625rem;
  }
  &.bottom {
    bottom: 0;
    right: 0;
    left: 0;
  }
  &-content {
    gap: 1rem;
  }
  &-content,
  &-content-ios {
    font-size: 0.875rem;
    border: 1px solid $general-border;
    background: $general;
    color: $on-general;
    border-radius: $border-radius-external;
    width: 90vw;
    max-width: 330px;
    margin-bottom: 1.25rem;
    padding: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    &-icon-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-button {
      align-self: center;
      width: 50%;
      height: 2.5rem;
      &::part(native) {
        border-radius: $border-radius-element;
        color: $on-accent;
        background: $accent;
        &:hover {
          background: $accent-hover;
        }
      }
    }
    &-icon {
      color: $on-general;
      fill: $on-general;
      font-size: 30px;
    }
    &-text {
      text-align: center;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      span {
        align-self: center;
        justify-content: center;
      }
      p {
        margin: 0;
      }
      &-bold {
        font-weight: 700;
      }
    }
  }
  &-content-ios.top::after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background-color: $general;
    border-left: 1px solid $general-border;
    border-bottom: 1px solid $general-border;
    top: -11px;
    left: 58%;
    transform: rotate(-225deg);
  }
  &-content-ios.bottom::after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background-color: $general;
    border-left: 1px solid $general-border;
    border-bottom: 1px solid $general-border;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }
}
