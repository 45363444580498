.splashscreen-page {
  background: black url(../../assets/images/splashscreen.jpg) center/cover
    no-repeat fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  .splashscreen-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
