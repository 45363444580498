@use '../../theme/variables.module.scss' as *;

.booking-ticket-container {
  background-color: $general;
  border-radius: $border-radius-external;
  .booking-ticket-col-icon {
    display: flex;
    justify-content: flex-end;
  }
  ion-row {
    padding: 0.5rem;
  }
  .booking-ticket-middle-col {
    border-left: 1px dashed $on-general;
    border-right: 1px dashed $on-general;
  }
}

.booking-ticket-header {
  display: flex;
  align-items: center;
}

.booking-ticket-delete-icon {
  font-size: 2rem;
  cursor: pointer;
}

.booking-ticket-icon {
  font-size: 3rem;
}
.booking-ticket-date,
.booking-ticket-hours {
  margin: 0.625rem 0;
  width: max-content;
  color: $on-general;
  font-size: 0.625rem;
  p {
    margin: 0;
  }
  > .date-title,
  .hours-title {
    font-weight: 300;
  }
  > .date-value,
  .hours-value {
    font-weight: 600;
  }
}

.booking-ticket-title {
  color: $on-general;
  font-size: 0.625rem;
  font-weight: 400;
  margin: 0;
}

.booking-ticket-subtitle {
  color: $on-general;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0;
}
