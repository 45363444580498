/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

ion-button {
  --background-activated: unset;
}

.app-container {
  filter: blur(0);
  transition: 500ms;
  &.blur {
    filter: blur(12px);
  }
}
