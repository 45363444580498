.error-handler-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-handler-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 0.31rem;
  padding: 0.625rem;
  height: 100px;
  ion-icon {
    font-size: 30px;
  }
  &-title {
    font-size: 0.75rem;
    padding-bottom: 0.625rem;
    margin: 0;
  }
  &-action {
    margin: 0;
  }
}
