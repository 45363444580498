@use '../../theme/variables.module.scss' as *;

.my-booking-calendar-grid {
  background-color: $primary-light;
  min-height: 100%;
  padding: 0;
}

.my-booking-calendar-row {
  height: 100%;
}
.my-booking-calendar-row {
  align-content: flex-start;
}

.my-booking-calendar-back {
  margin-top: 1rem;
  color: $on-primary-light;
  cursor: pointer;
}

.my-booking-calendar-title {
  text-align: center;
  font-size: 0.875rem;
  color: $on-primary-light;
  p {
    font-weight: 600;
  }
}

.my-booking-calendar-content {
  overflow: auto;
}

.my-booking-calendar-grid-column {
  padding-bottom: 0;
  &:last-of-type {
    padding-top: 0;
  }
}

.my-booking-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    color: $on-general;
  }
}

.my-calendar-content {
  overflow: auto;
  position: relative;
  border-radius: $border-radius-external;
  .fc-theme-standard .fc-scrollgrid {
    border: none;
    border-top: 1px solid $on-general-sub;
  }
  .fc {
    background: $general;
    .my-calendar-bullets-container {
      max-width: 100%;
      height: max-content;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;

      .my-calendar-bullet-service {
        width: 0.56rem;
        height: 0.56rem;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
      }
    }
    * {
      color: $on-general;
      font-size: 0.75rem;
    }
    th {
      padding: 0.625rem;
      a {
        font-weight: normal;
        font-size: 0.625rem;
      }
    }

    .fc-daygrid-day {
      transition: all 200ms linear;
      background-color: $general;
      overflow: hidden;
      font-weight: 400;
      border-radius: $border-radius-internal;
      cursor: pointer;
      &.fc-day-today .fc-daygrid-day-frame {
        border: 1px solid $general-border;
      }
      &.selected-date {
        background-color: $on-general;
        a {
          color: $general;
          font-weight: 600;
        }
      }
      &.disabled-date {
        background-color: $disabled;
        a {
          color: $on-disabled;
        }
      }
    }
    .fc-daygrid-day-frame {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .fc-daygrid-day-top {
      justify-content: center;
      align-items: center;
    }
    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      display: none;
    }
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0;
      padding: 0.625rem 0;
    }
    .fc-button-primary {
      background-color: transparent;
      border: none;
      &:not(:disabled):active {
        background-color: $disabled;
      }
    }
    .fc-toolbar-title {
      font-size: 0.625rem;
      font-weight: 400;
      text-align: center;
    }
    .fc-toolbar-chunk {
      &:first-child {
        margin-left: 0.625rem;
      }
      &:last-child {
        margin-right: 0.625rem;
      }
    }
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }
}

.my-calendar-bookings-title {
  font-size: 0.625rem;
  color: $on-primary-light;
  font-weight: 400;
}

.my-calendar-bookings-no-bookings {
  font-size: 0.94rem;
  text-align: center;
  color: $on-primary-light;
  letter-spacing: 0.2rem;
  p {
    margin: 0 auto;
    padding: 3rem 0;
    width: 210px;
  }
}

.my-calendar-bookings-list {
  background: $primary-light;
  ion-grid {
    margin-bottom: 1rem;
  }
}
