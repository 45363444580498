@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Thin.ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inconsolata';
  src: url('../assets/fonts/Inconsolata-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inria Serif';
  src: url('../assets/fonts/InriaSerif-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inria Serif';
  src: url('../assets/fonts/InriaSerif-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inria Serif';
  src: url('../assets/fonts/InriaSerif-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
