@use '../../theme/variables.module.scss' as *;

.user-info-name,
.user-info-details {
  text-align: center;
  p {
    margin: 0;
  }
}
.user-info-name {
  font-size: 0.94rem;
  font-weight: 500;
  color: $on-general;
  p {
    margin-bottom: 0.5rem;
  }
}
.user-info-details {
  font-size: 0.75rem;
  color: $on-general-sub;
  font-weight: 300;
  span {
    font-weight: 500;
  }
}
