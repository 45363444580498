/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@use './variables.module.scss' as *;
@import '~animate.css/animate.min.css';

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Poppins', sans-serif;
  /** primary **/
  --ion-color-primary: $primary;
  --ion-color-primary-rgb: 174, 119, 177;
  --ion-color-primary-contrast: $on-primary;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: $primary;
  --ion-color-primary-tint: $primary;

  /** secondary **/
  --ion-color-secondary: $secondary;
  --ion-color-secondary-rgb: 238, 243, 245;
  --ion-color-secondary-contrast: $on-secondary;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: $secondary;
  --ion-color-secondary-tint: $secondary;

  /** tertiary **/
  --ion-color-tertiary: $tertiary;
  --ion-color-tertiary-rgb: 196, 196, 196;
  --ion-color-tertiary-contrast: $on-tertiary;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: $tertiary;
  --ion-color-tertiary-tint: $tertiary;

  /** success **/
  --ion-color-success: $success;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: $on-success;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: $success;
  --ion-color-success-tint: $success;

  /** warning **/
  --ion-color-warning: $warning;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: $on-warning;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: $warning;
  --ion-color-warning-tint: $warning;

  /** danger **/
  --ion-color-danger: $danger;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: $on-danger;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: $danger;
  --ion-color-danger-tint: $danger;

  /** dark **/
  --ion-color-dark: $on-general;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: $general;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: $on-general;
  --ion-color-dark-tint: $on-general;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: $general;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: $on-general;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: $general;
  --ion-color-light-tint: $general;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

ion-button {
  --background-activated: unset;
  --color-hover: unset;
}
