@import './defaultVariables.scss';

// colors/main
$accent: var(--accent, $accent);
$accent-active: var(--accent-active, $accent-active);
$accent-border: var(--accent-border, $accent-border);
$accent-hover: var(--accent-hover, $accent-hover);
$accent-light: var(--accent-light, $accent-light);

$on-accent: var(--on-accent, $on-accent);
$on-accent-disabled: var(--on-accent-disabled, $on-accent-disabled);
$on-accent-light: var(--on-accent-light, $on-accent-light);
$on-accent-sub: var(--on-accent-sub, $on-accent-sub);

$primary: var(--primary, $primary);
$primary-active: var(--primary-active, $primary-active);
$primary-border: var(--primary-border, $primary-border);
$primary-hover: var(--primary-hover, $primary-hover);
$primary-light: var(--primary-light, $primary-light);

$on-primary: var(--on-primary, $on-primary);
$on-primary-disabled: var(--on-primary-disabled, $on-primary-disabled);
$on-primary-light: var(--on-primary-light, $on-primary-light);
$on-primary-sub: var(--on-primary-sub, $on-primary-sub);

$secondary: var(--secondary, $secondary);
$secondary-active: var(--secondary-active, $secondary-active);
$secondary-border: var(--secondary-border, $secondary-border);
$secondary-hover: var(--secondary-hover, $secondary-hover);
$secondary-light: var(--secondary-light, $secondary-light);

$on-secondary: var(--on-secondary, $on-secondary);
$on-secondary-disabled: var(--on-secondary-disabled, $on-secondary-disabled);
$on-secondary-light: var(--on-secondary-light, $on-secondary-light);
$on-secondary-sub: var(--on-secondary-sub, $on-secondary-sub);

$tertiary: var(--tertiary, $tertiary);
$tertiary-active: var(--tertiary-active, $tertiary-active);
$tertiary-border: var(--tertiary-border, $tertiary-border);
$tertiary-hover: var(--tertiary-hover, $tertiary-hover);
$tertiary-light: var(--tertiary-light, $tertiary-light);

$on-tertiary: var(--on-tertiary, $on-tertiary);
$on-tertiary-disabled: var(--on-tertiary-disabled, $on-tertiary-disabled);
$on-tertiary-light: var(--on-tertiary-light, $on-tertiary-light);
$on-tertiary-sub: var(--on-tertiary-sub, $on-tertiary-sub);

// colors/neutral

$disabled: var(--disabled, $disabled);
$on-disabled: var(--on-disabled, $on-disabled);

$general: var(--general, $general);
$general-active: var(--general-active, $general-active);
$general-border: var(--general-border, $general-border);
$general-hover: var(--general-hover, $general-hover);
$on-general: var(--on-general, $on-general);
$on-general-disabled: var(--on-general-disabled, $on-general-disabled);
$on-general-sub: var(--on-general-sub, $on-general-sub);

$inverse: var(--inverse, $inverse);
$inverse-active: var(--inverse-active, $inverse-active);
$inverse-border: var(--inverse-border, $inverse-border);
$inverse-hover: var(--inverse-hover, $inverse-hover);
$on-inverse: var(--on-inverse, $on-inverse);
$on-inverse-disabled: var(--on-inverse-disabled, $on-inverse-disabled);
$on-inverse-sub: var(--on-inverse-sub, $on-inverse-sub);

$negative: var(--negative, $negative);
$negative-active: var(--negative-active, $negative-active);
$negative-border: var(--negative-border, $negative-border);
$negative-hover: var(--negative-hover, $negative-hover);
$on-negative: var(--on-negative, $on-negative);
$on-negative-disabled: var(--on-negative-disabled, $on-negative-disabled);
$on-negative-sub: var(--on-negative-sub, $on-negative-sub);

// colors/semantic/danger

$danger: var(--danger, $danger);
$danger-active: var(--danger-active, $danger-active);
$danger-border: var(--danger-border, $danger-border);
$danger-hover: var(--danger-hover, $danger-hover);
$danger-light: var(--danger-light, $danger-light);
$on-danger: var(--on-danger, $on-danger);

// colors/semantic/info

$info: var(--info, $info);
$info-active: var(--info-active, $info-active);
$info-border: var(--info-border, $info-border);
$info-hover: var(--info-hover, $info-hover);
$info-light: var(--info-light, $info-light);
$on-info: var(--on-info, $on-info);

// colors/semantic/success

$success: var(--success, $success);
$success-active: var(--success-active, $success-active);
$success-border: var(--success-border, $success-border);
$success-hover: var(--success-hover, $success-hover);
$success-light: var(--success-light, $success-light);
$on-success: var(--on-success, $on-success);

// colors/semantic/warning

$warning: var(--warning, $warning);
$warning-active: var(--warning-active, $warning-active);
$warning-border: var(--warning-border, $warning-border);
$warning-hover: var(--warning-hover, $warning-hover);
$warning-light: var(--warning-light, $warning-light);
$on-warning: var(--on-warning, $on-warning);

//Token globali - Colori fissi
$gray-000: var(--gray-000, #000000);
$gray-10: var(--gray-10, #1a1a1a);
$gray-20: var(--gray-20, #333333);
$gray-30: var(--gray-30, #4d4d4d);
$gray-40: var(--gray-40, #666666);
$gray-50: var(--gray-50, #808080);
$gray-60: var(--gray-60, #999999);
$gray-70: var(--gray-70, #b3b3b3);
$gray-75: var(--gray-75, #bfbfbf);
$gray-80: var(--gray-80, #cccccc);
$gray-85: var(--gray-85, #d9d9d9);
$gray-90: var(--gray-90, #e6e6e6);
$gray-95: var(--gray-95, #f2f2f2);
$gray-100: var(--gray-100, #ffffff);

$white: var(--white, #ffffff);
$black: var(--black, #000000);

// values/border-radius

$border-radius-element: var(--border-radius-element, $border-radius-element);
$border-radius-external: var(--border-radius-external, $border-radius-external);
$border-radius-internal: var(--border-radius-internal, $border-radius-internal);
$border-radius-standard: var(--border-radius-standard, $border-radius-standard);

// values/border-width

$border-width-element: var(--border-width-element, $border-width-element);
$border-width-external: var(--border-width-external, $border-width-external);
$border-width-internal: var(--border-width-internal, $border-width-internal);
$border-width-standard: var(--border-width-standard, $border-width-standard);

:export {
  success: $success;
  danger: $danger;
  warning: $warning;
}
