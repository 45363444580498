@use '../../theme/variables.module.scss' as *;

.language-selector-menu {
  --width: 290px;
}

.language-selector {
  font-size: 0.875rem;
  background: none;
  color: $on-primary;
  &.sidebar {
    color: $on-general;
    ion-icon.selector-icon {
      color: $on-general;
    }
  }
  &.negative {
    color: $on-negative;
    ion-icon.selector-icon {
      color: $on-negative;
    }
  }
  &::part(native) {
    padding-right: 0 !important;
  }
  ion-icon.selector-icon {
    transform: rotate(0deg);
    position: unset;
    transition: 1s;
    margin-left: 0.5rem;
    transition: 500ms;
    color: $on-primary;
    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.header-dropdown {
  ion-backdrop {
    --backdrop-opacity: 0;
  }
  &.login .popover-wrapper {
    .popover-arrow.sc-ion-popover-ios {
      display: none;
    }
  }
}

.language-dropdown {
  margin: 0;
  --border-color: transparent;
  &::part(native) {
    background: $general;
    color: $on-general;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    &:hover {
      background: $general-hover;
    }
  }

  .language-label {
    width: 100%;
    text-align: left;
  }
}
