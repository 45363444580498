@use '../../theme/variables.module.scss' as *;

.action-alert {
  --max-width: 90%;
  --width: 23.4rem;
  .alert-wrapper {
    padding: 2rem;
    background-color: $general;
    border-radius: $border-radius-external;
    gap: 1rem;
    > div {
      padding: 0;
    }
  }
  .alert-sub-title {
    color: $on-general;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 300;
  }

  .alert-message p {
    color: $on-general;
    text-align: left;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
  }
  .alert-button-group {
    display: flex;
    flex-direction: column;
    align-content: center;
    row-gap: 1rem;
    padding: 0 1rem 1rem;
    .confirm-button {
      width: 100%;
      background-color: $accent;
      border: 1px solid $accent;
      border-radius: $border-radius-element;
      color: $on-accent;
      font-weight: 600;
      font-size: 0.75rem;
    }
    .cancel-button {
      border: 1px solid $accent;
      border-radius: $border-radius-element;
      font-size: 0.75rem;
      color: $accent;
      font-weight: 600;
    }
  }
}
