@use '../../theme/variables.module.scss' as *;

.loading-screen-page {
  background: $on-general url(../../assets/images/splashscreen.jpg) center/cover
    no-repeat fixed;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  .loading-screen-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-icon {
      color: $general;
      font-size: 135px;
      margin: 0 auto;
    }
    &-loader {
      color: $general;
      width: 3rem;
      height: 3rem;
    }
  }
}
