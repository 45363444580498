@use '../../theme/variables.module.scss' as *;

.service-worker-notification {
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 1rem auto 0;
  text-align: center;
  background: $general;
  box-shadow: 0 0.625rem 0.94rem rgba(0, 0, 0, 0.16);
  border-radius: $border-radius-external;
  .spinner-container {
    min-width: 10rem;
  }
  .tecma-btn.btn-small {
    margin-left: 0.5rem;
  }
}

.service-worker-button {
  font-size: 0.75rem;
  font-weight: 600;
  max-width: 13.75rem;
  min-width: 8rem;
  height: 2rem;
  margin: 0 auto;
  &::part(native) {
    background-color: $accent;
    color: $on-accent;
    border-radius: $border-radius-element;
    &:hover {
      background-color: $accent-hover;
    }
  }
}
