@use '../../theme/variables.module.scss' as *;

.alert-custom {
  .alert-wrapper {
    margin-bottom: 1rem;
    border-radius: $border-radius-element;
    .alert-head {
      padding: 0;
    }
    .alert-message {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $general;
      padding: 1rem;
      .alert-icon {
        font-size: 2rem;
        margin-right: 0.625rem;
        width: 10%;
      }
      .alert-text {
        margin: 0;
        width: 90%;
        color: $on-general;
      }
      .alert-footer {
        width: 100%;
        height: 0.31rem;
        position: absolute;
        bottom: 0px;
        left: 0;
      }
    }
  }
}
