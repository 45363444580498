@use '../../theme/variables.module.scss' as *;

ion-menu ion-content::part(background) {
  background: $general;
}

.split-pane-visible {
  .sidebar-title {
    text-align: center;
  }
  .sidebar-header-toolbar ion-buttons {
    display: none;
  }
}

.sidebar-project-logo {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 6rem;
  &-icon {
    display: inline;
    color: $general;
    opacity: 0.25;
  }
}

.sidebar-link-item {
  font-size: 0.75rem;
  font-weight: 300;
  cursor: pointer;
  &::part(native) {
    color: $on-general-sub;
    &:hover {
      background: $general-hover;
    }
  }
  &.selected::part(native) {
    font-weight: 500;
    color: $on-general;
    background: $general-active;
    &:hover {
      background: $general-hover;
    }
  }
  &.settings-link {
    border-bottom: 1px solid var(--ion-color-tertiary);
    border-top: 1px solid var(--ion-color-tertiary-tint);
  }
  &.agenda-link {
    border-bottom: 1px solid $general-border;
    border-top: 1px solid $general-border;
    &::part(native) {
      color: $on-general;
      &:hover {
        background: $general-hover;
      }
    }
    ion-icon {
      color: $on-general;
      font-size: 1rem;
    }
  }
}

.sidebar-close-button::part(native) {
  color: $on-primary-light;
}

.sidebar-header {
  position: relative;
  top: 30px;
  ion-icon {
    font-size: 1rem;
    margin-left: 1rem;
    --ion-color-base: $on-primary-light !important;
  }
  &-toolbar {
    --background: transparent;
    ion-button.button-clear {
      --background-hover: transparent;
    }
  }
}
.sidebar-title {
  font-size: 0.75rem;
  font-weight: 300;
  color: $on-primary-light;
}

.sidebar-top-background {
  background: $primary-light;
  height: 150px;
  position: relative;
  margin-bottom: 50px;
}

.sidebar-custom-grid {
  height: calc(100vh - 200px);
  padding-left: 0;
  padding-right: 0;
}

.sidebar-custom-row {
  height: 100%;
}

.sidebar-link-list {
  margin-top: 2rem;
  padding: 0.31rem 0;
}

.sidebar-language {
  display: flex;
  align-self: flex-end;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  &-version {
    font-size: 0.75rem;
    color: $on-general-sub;
  }
}

.sidebar-footer-icon-container {
  align-self: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  .sidebar-footer-icon {
    font-size: 30px;
    color: $on-general-sub;
  }
}

.logout-text {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.625rem;
}
